const generateErrorObj = (message: string, code: number = 422) => {
  const error = new Error(message);
  error.code = code;
  return error;
};

/* eslint-disable no-undef */
function onGotAllSlices(docDataSlices: Uint8Array[], resolve: (value: File) => void) {
  // Calculate the total size
  const totalSize = docDataSlices.reduce((acc, slice) => acc + slice.length, 0);

  // Create a combined Uint8Array
  const combinedArray = new Uint8Array(totalSize);
  let offset = 0;

  // Copy each slice into the combined array
  for (const slice of docDataSlices) {
    combinedArray.set(slice, offset);
    offset += slice.length;
  }

  // Convert to a Blob and create a File
  const blob = new Blob([combinedArray], { type: "application/pdf" });
  const pdfFile = new File([blob], "document.pdf", { type: "application/pdf" });

  resolve(pdfFile);
}

function getSliceAsync(
  file: Office.File,
  nextSlice: number,
  sliceCount: number,
  docDataSlices: Uint8Array[],
  resolve: (value: File) => void,
  reject: (reason: Error) => void
) {
  file.getSliceAsync(nextSlice, (sliceResult) => {
    if (sliceResult.status === Office.AsyncResultStatus.Succeeded) {
      const { data, index } = sliceResult.value;

      // Store the received slice in the array
      docDataSlices[index] = data;

      if (index + 1 < sliceCount) {
        // Fetch the next slice
        getSliceAsync(file, nextSlice + 1, sliceCount, docDataSlices, resolve, reject);
      } else {
        // All slices have been received, process them
        file.closeAsync();
        onGotAllSlices(docDataSlices, resolve);
      }
    } else {
      file.closeAsync();
      reject(generateErrorObj("Failed to retrieve document slices."));
    }
  });
}
export async function getDocumentAsPdfFile(): Promise<File> {
  return Word.run(() => {
    return new Promise<File>((resolve, reject) => {
      Office.context.document.getFileAsync(Office.FileType.Pdf, (result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const myFile = result.value;
          const sliceCount = myFile.sliceCount;
          const docDataSlices: Uint8Array[] = new Array(sliceCount);

          getSliceAsync(myFile, 0, sliceCount, docDataSlices, resolve, reject);
        } else {
          reject(generateErrorObj("Failed to process the document."));
        }
      });
    });
  });
}

export const getDocumentFilename = () => {
  const documentName = Office.context.document.url.split("/").pop();
  return documentName.split(".")?.[0];
};
